import React from "react"

const ContactForm = () => {
  return (
    <section className="contact-page-area section-gap">
      <div className="container">
        <div className="row">
          <div className="col-md-4 d-flex flex-column address-wrap">
            <div className="single-contact-address d-flex flex-row">
              <div className="icon">
                <span className="lnr lnr-home"></span>
              </div>
              <div className="contact-details">
                <h5>6A/10, New Kumaran Nagar, 2nd main road</h5>
                <p>Sholinganallur, Chennai, 600119</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="single-contact-address d-flex flex-row">
              <div className="icon">
                <span className="lnr lnr-phone-handset"></span>
              </div>
              <div className="contact-details">
                <a target="_blank" href="tel:+919655176317">
                  <h5>call@(+91) 9655176317</h5>
                </a>
                <br />
                <a target="_blank" href="https://wa.me/+919655176317">
                  <h5>whatsapp@(+91) 9655176317</h5>
                </a>

                {/* <p>Mon to Fri 9am to 6 pm</p> */}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="single-contact-address d-flex flex-row">
              <div className="icon">
                <span className="lnr lnr-envelope"></span>
              </div>
              <div className="contact-details">
                <h5>
                  <a
                    href="mailto:contact@madhuraminteriors.com"
                    className="__cf_email__"
                  >
                    contact@madhuraminteriors.com
                  </a>
                </h5>
                <p>Enquiry us your query anytime!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
